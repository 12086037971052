import axios from "axios";

/**
 * This is the API call that lazily loads media for a user.
 * ToDo: Do I need the pageParam?
 */
export const postPixel = async (
  userId,
  eventId,
  fbp
) => {
  let response;
  
  const encodedUserId = userId.replace("#", "%23");
  const endpoint = `https://${process.env.REACT_APP_DOMAIN}/api/pixel?u=${encodedUserId}&eid=${eventId}&fbp=${fbp}`;
  response = await axios.get(endpoint);
};
