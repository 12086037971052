import "./App.css";
import { useEffect, useState } from "react";
import Header from "./components/Header";
import TabGroup from "./components/TabGroup";
import "../src/css/Grid.css";
import Main from "./components/Main";
import GoogleAnalyticsScript from "./components/GoogleAnalyticsScript";
import { useLoaderData, useLocation, useNavigate } from "react-router-dom";
import ReactPixel from "react-facebook-pixel"; // Import react-facebook-pixel
import {postPixel} from './api/postPixel.js';
import Cookies from "js-cookie"; 

const getCookieValue = (key) => {
  // Use js-cookie to read the cookie value
  return Cookies.get(key);
};

function getEventId(user) {
//  return  'event_' + user.userId.replace('userId#','') + '_'  + new Date().getTime();
    return  'event_'   + new Date().getTime();

}

function App() {
  const user = useLoaderData();
  const location = useLocation(); // Access the location object for route info
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  
 
  // Determine the initial tab based on user settings and query parameters
  const determineInitialTab = () => {
    if (user.tiktokEnabled && !user.fbEnabled) {
      return "TIKTOK";
    }
    if (user.fbEnabled && !user.tiktokEnabled) {
      return "INSTAGRAM";
    }
    return query.get("tab") === "tiktok" ? "TIKTOK" : "INSTAGRAM";
  };

  const [selectedSource, setSelectedSource] = useState(determineInitialTab);

  // Prevent redundant navigation and infinite loop
  useEffect(() => {
    const currentTab = query.get("tab");
    if (
      (selectedSource === "TIKTOK" && currentTab !== "tiktok") ||
      (selectedSource === "INSTAGRAM" && currentTab)
    ) {
      navigate(selectedSource === "TIKTOK" ? `?tab=tiktok` : "", { replace: true });
    }
  }, [selectedSource, location.search, navigate]);

  const handleTabChange = (source) => {
    setSelectedSource(source);
  };



   useEffect(() => {
    if (user.metaPixelId && user.metaPixelId.length > 0) {
      //  ReactPixel.pageView(); // Track page view
      ReactPixel.init(user.metaPixelId); 
     const event_id = getEventId(user);
     const fbp = getCookieValue("_fbp");
     
      ReactPixel.trackSingle(user.metaPixelId, 'PageView', 
        {
          
          event_id: event_id,
        
        });
      //Make this call conditional based on if they have a Conversions API Token.
      postPixel(user.userId, event_id,fbp);
     
    } else {
   //   console.log('No change', user.userId);
    }
    
  }, [user, location.pathname]);


  // Conditional rendering logic
  const showTabGroup = user.fbEnabled && user.tiktokEnabled;

  return (
    <>
      <GoogleAnalyticsScript />
      <Header user={user} />
      {showTabGroup && (
        <TabGroup onTabChange={handleTabChange} activeTab={selectedSource} />
      )}
      {selectedSource === "INSTAGRAM" && user.fbEnabled && (
        <Main user={user} source="INSTAGRAM" />
      )}
      {selectedSource === "TIKTOK" && user.tiktokEnabled && (
        <Main user={user} source="TIKTOK" />
      )}
    </>
  );
}

export default App;
